<template>
  <d-remote-monitoring-messages-templates />
</template>

<script>

export default {
  name: 'RemoteMonitoringMessagesTemplates',
  page: {
    title: 'CRM Doctis - Шаблоны сообщений ДМ',
  },
};
</script>
